import TextButtonImageSection from '../components/TextButtonImageSection';
import bigi_jpeg from '../assets/PageNotFoundPage/big_i.jpeg';

const PageNotFound = () => {
    return (
        <TextButtonImageSection
          config={{
            imgSrc: bigi_jpeg,
            minHeight: '80vh',
            imgConfig: { tintColor: '#353631', tintOpacity: 0.3, attachment: 'fixed', positionY: '35%' },
            headerText: 'Page Not Found',
          }}
        />
    )
}

export default PageNotFound;