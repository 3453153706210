import React, { useEffect, useState } from 'react';
import TextButtonImageSection from '../components/TextButtonImageSection';
import ProfileInformation from '../components/ProfilePage/ProfileInformation';
import sk_late_night_jpeg from '../assets/ProfilePage/sk_late_night.jpeg';

const Profile = () => {

  useEffect(() => {
    document.title = 'Profile Information';
  }, []);

  return (
    <div style={{ overflow: 'hidden' }}>
        <TextButtonImageSection
          config={{
            imgSrc: sk_late_night_jpeg,
            minHeight: '50vh',
            imgConfig: { tintColor: '#353631', tintOpacity: 0.3, attachment: 'fixed', positionY: '35%' },
            headerText: 'Profile Information',
          }}
        />
        <ProfileInformation />
    </div>
  );
};

export default Profile;
