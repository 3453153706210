import Row from 'react-bootstrap/Row';
import {brothers, configKeys, activeYears, officers} from '../../config';
import BrotherCard from './BrotherCard';
import styles from '../../styles/BrothersPage/BrotherCardsSection.module.css';

const BrotherCardsSection = () => {
    const classYears: string[] = Object.values(activeYears);
    const positionKeys: string[] = Object.keys(officers);
    const namesToPositions: {[key: string]: string} = {};
    positionKeys.forEach((position, idx) => {
        var officerNames: string | string[] = officers[position][configKeys.Name];
        if (typeof officerNames === 'string') {
            officerNames = [officerNames];
        }
        officerNames.forEach(officerName => {
            if (namesToPositions[officerName] === undefined) {
                namesToPositions[officerName] = position;
            } else {
                namesToPositions[officerName] += ", " + position;
            }
        });
    })

    return (
        // Have to add +1 to number of desired cards per row due to spacing between cards
        <Row sm={1} md={2+1} lg={5+1} className={styles.rowContainer}>
            {classYears.map((classYear, idx1) => {
                const brotherNames: string[] = Object.keys(brothers[classYear]);
                return (
                    <>
                    <p key={`brothers-cards-section-header-${idx1}`} className={styles.classYearTitle} id={classYear}>Class of {classYear}</p>

                    {brotherNames.map((brotherName, idx2) => {
                            const lowerUnderscoredName: string = brotherName.toLowerCase().split(' ').join('_');
                            const major: string = brothers[classYear][brotherName][configKeys.Major];
                            const hometown: string = brothers[classYear][brotherName][configKeys.Hometown];
                            const interests: string = brothers[classYear][brotherName][configKeys.Interests];
                            const listKey: string = `brothers-cards-section-card-${idx1}-${idx2}`;
                            return <BrotherCard key={listKey} first_last={lowerUnderscoredName} brotherInfo={{
                                // Class year used to find image in appropriate folder, does not show up in card, other attributes should
                                class_year: classYear,
                                major: major,
                                hometown: hometown,
                                interests: interests,
                                position: (brotherName in namesToPositions) ? namesToPositions[brotherName] : ''
                            }}/>
                        }
                    )}
                    </>
                )
            })}

        </Row>
    )
}

export default BrotherCardsSection;
