import { Container, Row, Col } from 'react-bootstrap';
import styles from '../styles/TextLinkSection.module.css';


export interface TextLinkSectionConfig {
    sectionText: string;
    linkText?: string;
    link?: string;
    paddingTop: number;
    paddingMiddle?: number;
    paddingBottom: number;
  }
  
  const TextLinkSection = ({ sectionText, linkText, link, paddingTop, paddingMiddle, paddingBottom }: TextLinkSectionConfig) => {
    const containerStyle = {
      paddingTop: `${paddingTop}rem`,
      paddingBottom: `${paddingBottom}rem`,
    };
  
    const pStyle = {
      paddingBottom: `${paddingMiddle}rem`,
    };

    return (
      <Container fluid className={styles.container} style={containerStyle}>
        <Row className={styles.sectionRow}>
          <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }} className={styles.sectionCol}>
            <p className={styles.sectionText} style={pStyle}>{sectionText}</p>
            {linkText && <a className={styles.linkText} href={link}>{linkText}</a>}
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default TextLinkSection;
