/*
  Creator: Rom Fradkin
  Email: fradkin.rom@gmail.com
  Date: June 28, 2023

  Description: Page for updating profile information
*/
import { useState, useEffect, FormEvent, ChangeEvent, Dispatch, SetStateAction } from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import axios from 'axios'

// Styles
import styles from '../../styles/ProfilePage/ProfileInformation.module.css'

// Utils
import getPersistentDynamicId from '../../utils/cookieFunctions'


const ProfileInformation = () => {

    const [profilePicture, setProfilePicture] = useState<string>("")
    const [linkedin, setLinkedin] = useState<string>("")
    const [classYear, setClassYear] = useState<string>("")
    const [familyLine, setFamilyLine] = useState<string>("")
    const [otherNotes, setOtherNotes] = useState<string>("")

    const [persistentId, _] = getPersistentDynamicId()

    useEffect(() => {
        fetch('https://phibetaepsilon.org/getProfile', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                persistentId: persistentId
            })
        }).then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Error: ' + response.status)
            }
        }).then((data) => {
            setLinkedin(data.linkedin)
            setFamilyLine(data.familyLine)
            setOtherNotes(data.otherNotes)
            setClassYear(data.classYear)
        })
    }, [])

    const inputChangeHandler = (setFunction: Dispatch<SetStateAction<string>>, 
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFunction(event.target.value)
    }

        const handleFileChange = (event:any) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            if (reader.result !== null) {
            const base64String = reader.result.toString().replace('data:', '').replace(/^.+,/, '');
            console.log(base64String); // Print the base64 string
            setProfilePicture(base64String);
            }
        };

        reader.readAsDataURL(file);
        };


    const handleButtonClick = (event: FormEvent): void => {
        event.preventDefault(); // Prevent default form submission

        axios.post('https://phibetaepsilon.org/updateProfile', {
            linkedin: linkedin,
            familyLine: familyLine,
            classYear: classYear,
            otherNotes: otherNotes,
            profilePicture: profilePicture,
            persistentId: persistentId
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.status === 200) {
                window.location.href = '/connect'
            } else {
                throw new Error('Error: ' + response.status)
            }
        }).catch((error) => {
            console.error('Error: ', error)
        })
    }
  
    // Since when you enter data, you can't see what information
    // should be in the box, there should be a text title above each box explaining
    // what it is for (that always remains). I don't care enough to implement this
    // and I think its functional without it, but if any N sees this in the future,
    // feel free to implement it (same goes for the login/signup page, but it is)
    // less important there since there is no updating your information/filling old
    // information, so you really only have to remember the placeholder right after
    // you start typing, which everyone should be able to do
    return (
        <Container fluid className={`${styles.formContainer} d-flex justify-content-center align-items-center`}>
            <Col xs={12} sm={10} md={8} lg={7} xl={6} className={`${styles.form} mt-5 mb-5`}>
                <Form className='text-center' onSubmit={handleButtonClick}>
                    <h1 className={`${styles.linkTitle} mt-3 mb-3`}>{"Update your information"}</h1>
                    <Form.Group className="d-flex align-items-center justify-content-center">
                        <Form.Control
                            className={`${styles.input} ${styles.inputLinks} w-75 mb-1`}
                            as="input"
                            placeholder="LinkedIn"
                            defaultValue={`${linkedin}`}
                            onChange={(e) => inputChangeHandler(setLinkedin, e)}
                        />
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center justify-content-center">
                        <Form.Control
                            className={`${styles.input} ${styles.inputLinks} w-75 mb-1`}
                            as="input"
                            placeholder="Class Year"
                            defaultValue={`${classYear}`}
                            onChange={(e) => inputChangeHandler(setClassYear, e)}
                        />
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center justify-content-center">
                        <Form.Control
                            className={`${styles.input} ${styles.inputLinks} w-75 mb-1`}
                            as="textarea"
                            rows={4} 
                            placeholder="Family Line (Grandbig, Big, Little, Grandlittle, etc) if known or applicable"
                            defaultValue={`${familyLine}`}
                            onChange={(e) => inputChangeHandler(setFamilyLine, e)}
                        />
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center justify-content-center">
                        <Form.Control
                            className={`${styles.input} ${styles.inputLinks} w-75 mb-3`}
                            as="textarea"
                            rows={4} 
                            placeholder="Other Notes (favorite PBE memory, interesting facts, etc)"
                            defaultValue={`${otherNotes}`}
                            onChange={(e) => inputChangeHandler(setOtherNotes, e)}
                        />
                    </Form.Group>

                    <div className='mb-3'>
                        <label onChange={(e) => handleFileChange(e)} htmlFor="formId">
                            <input name="" type="file" id="formId" accept="image/jpeg" hidden />
                            <div className={styles.fileUpload}>
                                {profilePicture === "" ? "Upload Profile Picture (JPEG)" : "Picture Uploaded" }
                            </div>
                        </label>
                    </div>

                    <Button
                        className={`${styles.submit} mb-4`}
                        variant="primary"
                        size="lg"
                        type="submit"
                    >
                        {'Submit'}
                    </Button>
                </Form>
            </Col>
        </Container>
    )
}

export default ProfileInformation