import React, { useEffect } from 'react';

// Components
import TextButtonImageSection from '../components/TextButtonImageSection';
import TextLinkSection from "../components/TextLinkSection";
import {officers, configKeys} from '../config';

// Images
import boston_roof_jpeg from '../assets/AwaitVerificationPage/boston_roof.jpeg';

const AwaitVerification = () => {

    useEffect(() => {
        document.title = 'Await Verification'
     }, []);


    return (
        <div style={{overflow: 'hidden'}}>
            <TextButtonImageSection config={{
                    imgSrc: boston_roof_jpeg,
                    minHeight: '45vh',
                    headerText: 'Await Verification',
                    imgConfig: {tintColor: '#353631', tintOpacity: 0.3, attachment: 'fixed', positionY: '95%'},
                }}/>
            <TextLinkSection 
                sectionText={`Thank you for signing up to connect with brothers. Every verification is done manually, so you will recieve an email when your account is verified. Please reach out to ${officers[configKeys.N][configKeys.Kerberos]}@mit.edu if you do not recieve your verification within a week.`}
                linkText="Please click here to return home"
                link="/"
                paddingTop={6}
                paddingMiddle={3}
                paddingBottom={6}
            />
        </div>
    )
}

export default AwaitVerification;