// Images
import livingRoomPristineColor_jpeg from '../../assets/HousePage/CardImages/phi-beta-epsilon-mit-epstein-joslin-3-660x440.jpeg';
import poolRoomNoBeerOrShitColor_jpeg from '../../assets/HousePage/CardImages/phi-beta-epsilon-mit-epstein-joslin-6-660x440.jpeg';
import diningRoomVeryClean_jpeg from '../../assets/HousePage/CardImages/phi-beta-epsilon-mit-epstein-joslin-7-660x440.jpeg';
import cleanestFourthLoungeHasEverBeen_jpeg from '../../assets/HousePage/CardImages/phi-beta-epsilon-mit-epstein-joslin-3-740x493.jpeg';
import nonExistantLoungeWithGeeds_jpeg from '../../assets/HousePage/CardImages/phi-beta-epsilon-mit-epstein-joslin-11-660x535.jpeg'
import sexyRooftopDinner_jpeg from '../../assets/HousePage/CardImages/phi-beta-epsilon-mit-epstein-joslin-6-740x529.jpeg'
import acrossTheRiverHouseSnipe_jpg from '../../assets/HousePage/CardImages/d88124dbd9f38e77f2c4d7909e9667e8-1-790x640.jpg'
import oleReliable_jpeg from '../../assets/HousePage/CardImages/img-292-660x495.jpeg';
import fourHundredMoneyShot_jpg from '../../assets/HousePage/CardImages/phi-beta-epsilon-mit-epstein-joslin-9-660x660.jpg';

// Components
import FlippyCardSection, { CardInfo } from '../FlippyCardSection';

const HouseImageCardSection = () => {
    const cards: CardInfo[][] = [
        [
            {
                img_src: livingRoomPristineColor_jpeg,
                card_title: 'LIVING ROOM',
                card_description: 'Our living room is the most versatile area of the house. It is used for house meeting and playing the piano, but is also cleared out and used for parties.'},
            {
                img_src: poolRoomNoBeerOrShitColor_jpeg,
                card_title: 'POOL TABLE ROOM',
                card_description: 'Never played pool? Our in-house pool table always has some friendly competition to better your game.'},
            {
                img_src: diningRoomVeryClean_jpeg,
                card_title: 'DINING ROOM & KITCHEN',
                card_description: 'Our dining room is where brothers come to eat delicious and diverse dinners every school night cooked by our in house chef: Chef LaKiesha.'},
        ],
        [
            {
                img_src: cleanestFourthLoungeHasEverBeen_jpeg,
                card_title: 'FLOOR STUDY SPACES',
                card_description: 'These study areas are located on floors 3, 4, and 5. They come with wall to wall whiteboards and couches to study in groups or just hangout after a rigorous day at MIT.'},
            {
                img_src: nonExistantLoungeWithGeeds_jpeg,
                card_title: 'ELEVATOR LOUNGES',
                card_description: 'Each floor also has an elevator lounge looking out over campus. They have been converted to additional study spaces, complete with adjustable standing desks!'},
            {
                img_src: sexyRooftopDinner_jpeg,
                card_title: 'ROOFTOP DINING',
                card_description: 'Our roof is the most unique aspect of the house, with a 360 view of campus, Boston, and Fenway Park. It is the perfect dining location for when we have faculty over to socialize with the brothers!'},
        ],
        [
            {
                img_src: acrossTheRiverHouseSnipe_jpg,
                card_title: 'ON CAMPUS LOCATION',
                card_description: 'Our location on campus is unique to independent living groups. It is especially useful in the cold Boston winters, with the walk to class being only a few minutes.'},
            {
                img_src: oleReliable_jpeg,
                card_title: 'ROOFTOP DECK',
                card_description: 'Our roof is often set up for entertainment, studying, or hanging out. In the warmer months, it is hard to find a day without a brother working hard or playing harder on the roof.'},
            {
                img_src: fourHundredMoneyShot_jpg,
                card_title: 'ATHLETIC FACILITIES',
                card_description: 'With a house full of athletes, our location to fields and facilities is crucial. Brothers can get to practices and games quickly, while fans can watch them play from the comfort of our roof!'},
        ]
    ]
    return (
        <FlippyCardSection section_title='Hover over the images below to learn more about our house!' cards_matrix={cards}/>
    )
}

export default HouseImageCardSection;