import { Container, Row, Col } from 'react-bootstrap';
import styles from '../../styles/ConnectPage/UserInformationCard.module.css';
import React from 'react';

interface UserInformationCardProps {
  key: number
  persistentId: string
  name: string
  email: string
  classYear: string
  linkedin: string;
  otherNotes: string
}

const UserInformationCard: React.FC<UserInformationCardProps> = 
    ({ persistentId, name, email, classYear, linkedin, otherNotes }: UserInformationCardProps) => {
  return (
    // <Col>
    <Col xl={5} lg={11} md={11} sm={11} xs={11} className={`m-2 ${styles.container}`}>
        <Row>
            <Col className='text-center col-5'>
                {/* Temp Solution. Add a random number to force reload each image, as if you don't the old
                images will cache and when you update your profile image, it won't show up as a 
                new image. Should really reset only the image of the persistentId each time if they
                visitied the profile page, should change later to do that */}
                <img 
                    src={`https://phibetaepsilon.org/images/profilePictures/${persistentId}.jpeg?${Math.random()}`} 
                    onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                        // Alternate URL if no profile picture
                        e.currentTarget.src = 
'https://media.istockphoto.com/id/1332100919/vector/man-icon-black-icon-person-symbol.jpg?s=61\
2x612&w=0&k=20&c=AVVJkvxQQCuBhawHrUhDRTCeNQ3Jgt0K1tXjJsFy1eg='
                      }}
                    alt="Image Description" 
                    className={`mt-2 mb-2 ${styles.profilePicture}`}/>
            </Col>
            <Col className={`d-flex align-items-center justify-content-start m-0 ${styles.titleText}`}>
                <Row>
                    <div>{name}</div>
                    <div>{email}</div>
                    {classYear ? <div>Class of {classYear}</div> : null}
                </Row>
            </Col>
        </Row>
      <hr className={styles.horizontalLine} />
      <Row className={`text-center pb-2 ${styles.mainText}`}>
        <Col>
          <span className={styles.subtitleText}>LinkedIn: </span>
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            {linkedin}
          </a>
        </Col>
      </Row>
      <Row className={`text-center pb-2 ${styles.mainText}`}>
        <Col>
          <span className={styles.subtitleText}>Other Notes: </span>
          {otherNotes}
        </Col>
      </Row>
    </Col>
  );
};

export default UserInformationCard;
