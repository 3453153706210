import React, { useEffect, useState } from 'react';
import TextButtonImageSection from '../components/TextButtonImageSection';
import initiation_dinner_jpeg from '../assets/ConnectPage/initiation_dinner.jpeg';
import UserInformationCard from '../components/ConnectPage/UserInformationCard';
import { GiCog } from 'react-icons/gi';

import { Container, Row, Col } from 'react-bootstrap';

const Connect = () => {

    interface Profile {
        persistentId: string;
        firstName: string;
        lastName: string;
        classYear: string;
        email: string;
        linkedin: string;
        otherNotes: string
      }
      
      const [profiles, setProfiles] = useState<Profile[]>([]);
      

    useEffect(() => {
        fetch('https://phibetaepsilon.org/getAllProfiles', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Error: ' + response.status)
            }
        }).then((data) => {
            console.log(data)
            setProfiles(data)
        })
    }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
        <TextButtonImageSection
          config={{
            imgSrc: initiation_dinner_jpeg,
            minHeight: '50vh',
            imgConfig: { tintColor: '#353631', tintOpacity: 0.3, attachment: 'fixed', positionY: '5%' },
            headerText: 'Connect',
            buttonText: 'Update Your Profile',
            buttonLink: '/profile',
            descriptionText: 'A place to connect with brothers',
            buttonIcon: <GiCog/>
          }}
        />
        <Row className="justify-content-center">

        {profiles.map((profile, index) => (
            <UserInformationCard 
                key={index} // Add a unique key prop
                persistentId={profile.persistentId}
                name={`${profile.firstName} ${profile.lastName}`}
                classYear={profile.classYear}
                email={profile.email}
                linkedin={profile.linkedin}
                otherNotes={profile.otherNotes}
            />
        ))}

        </Row>
    </div>
  );
};

export default Connect;
