import {useEffect} from 'react';
import styles from '../styles/HistoryPage/HistoryPage.module.css';

// Components
import TextButtonImageSection from '../components/TextButtonImageSection';
import Timeline from '../components/HistoryPage/Timeline';

// Images
import killianDomeBlackWhite_jpeg from '../assets/HistoryPage/killian-court-and-main-building-with-great-dome-mit-massachusetts-institute-of-technology-boston-usa-joe-fox-900x600.jpeg'

const HistoryPage = () => {
    useEffect(() => {
        document.title = 'History'
     }, []);

    // Config for video player
    const ytPlayer: {videoId: string, config: {key: string, val: string | number}[]} = {
        videoId: '7ggRCsrvaD8',
        config: [
            {key: 'autoplay', val: 1},
            {key: 'mute', val: 1},
            {key: 'loop', val: 1},
            {key: 'playlist', val: '7ggRCsrvaD8'},
            {key: 'controls', val: 0},
            {key: 'disablekb', val: 1},
            {key: 'vq', val: 'hd720'},
        ]
    }

    return (
        <div>
            <TextButtonImageSection config={{
                imgSrc: killianDomeBlackWhite_jpeg,
                minHeight: '80vh',
                imgConfig: {tintColor: '#373737', tintOpacity: 0.75, attachment: 'fixed'},
                headerText: 'History',
                descriptionText: 'Alumni: Please update your info at the link below to stay connected with MIT!',
                buttonText: 'ALUM.MIT.EDU',
                buttonLink: 'https://alum.mit.edu'
            }}/>
            <div style={{background: 'linear-gradient(#202020, #545454)'}}>
                <iframe
                    src={`https://www.youtube-nocookie.com/embed/${ytPlayer.videoId}?${ytPlayer.config.map(config => `${config.key}=${config.val}&`).join('')}`}
                    title="YouTube video player"
                    className={styles.videoPlayer}
                />
            </div>
            <Timeline/>
            
        </div>
    )
}

export default HistoryPage;