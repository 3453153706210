import { BrowserRouter, Route, Router, Routes, useLocation } from 'react-router-dom';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import PrivateRoutes from '../utils/PrivateRoutes';

// Pages
import HomePage from './HomePage';
import BrothersOnlyPage from './BrothersOnlyPage';
import ProfilePage from './ProfilePage';
import ConnectPage from './ConnectPage';
import AwaitVerificationPage from './AwaitVerificationPage';
import BrothersOnlyAccess from './BrothersOnlyAccessPage';
import BrothersPage from './BrothersPage';
import HousePage from './HousePage';
import RushPage from './RushPage';
import CareersPage from './CareersPage';
import ImpactPage from './ImpactPage';
import HistoryPage from './HistoryPage';
import FamilyTree from './FamilyTree/FamilyTreePage';
import PhpMyAdminPage from './PhpMyAdminPage';
import PageNotFound from './PageNotFound';


// We wrap the inner in the BrowserRouter so that we can use useLocation() to conditionally render the navbar and footer
const PageRoutes = () => {
  return (
    <BrowserRouter>
        <Inner />
    </BrowserRouter>
  );
};

const Inner = () => {
    const location = useLocation();
    return (
        <>
        {location.pathname !== '/familytree' && <NavigationBar />}

        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route element={<PrivateRoutes requireActive={false}/>}>
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/connect" element={<ConnectPage />} />
            </Route>
            <Route element={<PrivateRoutes requireActive={true}/>}>
                <Route path='/brothersOnly' element={<BrothersOnlyPage/>} />
            </Route>
            <Route path="/AwaitVerification" element={<AwaitVerificationPage />} />
            <Route path="/brothersOnlyAccess" element={<BrothersOnlyAccess />} />
            <Route path="/brothers" element={<BrothersPage />} />
            <Route path="/house" element={<HousePage />} />
            <Route path="/rush" element={<RushPage />} /> {/* Change to rush vs cpw depending on semester */}
            <Route path="/careers" element={<CareersPage />} />
            <Route path="/impact" element={<ImpactPage />} />
            <Route path="/history" element={<HistoryPage />} />
            <Route path="/phpmyadmin" element={<PhpMyAdminPage />} />
            <Route
                path="/familytree"
                element={<FamilyTree />}
            />
            <Route path="*" element={<PageNotFound />} />
        </Routes>

        {location.pathname !== '/familytree' && <Footer />}
        </>
    );
}

export default PageRoutes;
