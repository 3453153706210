import { useEffect } from "react";
import TextInfoSection from "../components/TextInfoSection";
import EventScheduleTable from "../components/RushPage/EventScheduleTable";
import styles from "../styles/RushPage/RushPage.module.css";

// For some reason mov files will crash the app so just use mp4
// import rushVideo_mp4 from '../assets/RushPage/rush_video.mp4';

const RushPage = () => {
  const rushVideo_mp4: string = "videos/rush_video.mp4";
  useEffect(() => {
    document.title = "Rush";
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      {/* This can't be an iframe with embedded youtube video because youtube does not support
                autoplay on mobile.
                See here for explanation: https://developers.google.com/youtube/iframe_api_reference#Mobile_considerations  */}
      {/* Video will not autoplay on iphone if low power mode enabled */}
      <video
        className={styles.ytPlayer}
        autoPlay
        muted={true}
        loop
        playsInline
        poster=""
      >
        <source src={rushVideo_mp4} type="video/mp4"></source>
        Sorry, your browser does not support HTML5 video.
      </video>
      <TextInfoSection sectionText="Phi Beta Epsilon is a blend of academic, athletic, and social men striving to better themselves in MIT’s competitive atmosphere. Its community on campus fosters lifelong relationships with those living in the house and the hundreds who previously lived at 400." />
      <EventScheduleTable />
    </div>
  );
};

export default RushPage;
