/*
  Creator: Rom Fradkin
  Email: fradkin.rom@gmail.com
  Date: June 28, 2023

  Description: Page for ACTIVE brothers only. Can be modified in future to contain more
  useful information, such as Dropboxes, other links, etc
*/

// Functionality
import { useEffect, useState } from 'react';
import axios from 'axios'

// Components
import TextButtonImageSection from '../components/TextButtonImageSection';
import Submissions from "../components/BrothersOnlyPage/Submissions"
import Recommendation from "../components/BrothersOnlyPage/Recommendation"

// Images
import nooj_jpeg from '../assets/BrothersOnly/nooj.jpeg';

// Styles
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import styles from '../styles/BrothersOnlyPage/BrothersOnly.module.css'

const BrothersOnlyPage = () => {

	const [link, setLink] = useState("")
    const [firstName, setFirstName] = useState("")

    useEffect(() => {
      	axios.post('https://phibetaepsilon.org/getLink', {}, {
          	headers: {
            	'Content-Type': 'application/json'
          	}
      	})
      	.then((response) => {
          	if (response.status === 200) {
              	return response.data;
          	} else {
            	throw new Error('Error: ' + response.status);
          	}
		})
		.then((data) => {
			setLink(data.link);
			setFirstName(data.firstName);
		})
		.catch((error) => {
			console.error('Error:', error);
		})
  
      document.title = 'Brothers Only';
  	}, [])

    return (
      <div className={styles.background}>
          <TextButtonImageSection 
            config={{
              imgSrc: nooj_jpeg,
              minHeight: '40vh',
              headerText: 'Brothers Only',
              }} 
          />
          <Submissions />
    
		 <Recommendation link={link} firstName={firstName}/>
      </div>
    )
}

export default BrothersOnlyPage;