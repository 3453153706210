import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import styles from "../../styles/HousePage/SummerHousingDropdown.module.css";

interface IAccordion {
  buttonHeader: string;
  buttonText: {
    textTitle: string;
    textDescription?: string;
    textNode?: JSX.Element;
  }[];
}

const APPLICATION_LINK = "https://docs.google.com/forms/d/e/1FAIpQLSeaV6v1Fi1ihRkNzGam7BzYDVIujFSfbHGeQ19UNg0COluGYQ/viewform";

const SummerHousingDropdown = () => {
  const accordion: IAccordion[] = [
    {
      buttonHeader: "Why PBE?",
      buttonText: [
        {
          textTitle: "Comfort",
          textDescription:
            "The house has been newly renovated, so all facilities (bathrooms on each floor, free laundry, professional kitchen, central air conditioning, study spaces, recreational spaces, etc.) are new!",
        },
        {
          textTitle: "Convenience",
          textDescription:
            "Fully furnished rooms: long twin mattress, desk, and wardrobe closet. Arrangements can be made for brothers to leave refrigerators, couches, TVs, and other furniture.",
        },
        {
          textTitle: "Location",
          textDescription: "On MIT Campus and minutes from public transit!",
        },
        {
          textTitle: "Safety",
          textDescription:
            "MIT campus is monitored 24/7 by MIT Police and every entrance to the house (including roof) is secured.",
        },
        {
          textTitle: "Fun",
          textDescription:
            "We are working to fill the house with diverse and exciting people for the summer. Be a part of it!",
        },
        {
          textTitle: "Roofdeck",
          textDescription:
            "A hallmark to living at PBE is access to our roof deck. The pictures speak for themselves!",
        },
      ],
    },
    {
      buttonHeader: "Logistics",
      buttonText: [
        {
          textTitle: "Houseworks",
          textDescription:
            "All residents are required to take part in a houseworks system where residents must regularly fulfill basic cleaning duties to ensure the cleanliness of the house.",
        },
        {
          textTitle: "Security Deposit",
          textDescription:
            "A $800 security deposit is required to secure your room at PBE. This deposit will be used to cover the cost of a required meal plan, which is not included in rent. We expect the meal plan to cost ~$700, so if your room is in good standing at the end of the summer, you can expect ~$100 back.",
        },
        {
          textTitle: "Parking",
          textDescription: "We have a few parking spots available for rent, which can be reserved. No need to specify if you want parking on your application, this is discussed later in the housing process. If you do not wish to live at PBE but are interested in renting a parking spot for the summer, please email lvenon@mit.edu."
          // textNode: (
          //   <p className={styles.textDescription}>
          //     We do NOT offer reserved parking. There is public street parking
          //     around the house, but this cannot be reserved. The closest parking
          //     garages are in Kendall Square (
          //     {
          //       <a
          //         href="http://web.mit.edu/facilities/transportation/parking/locations.html"
          //         target="_blank"
          //       >
          //         MIT Parking Website
          //       </a>
          //     }
          //     )
          //   </p>
          // ),
        },
        {
          textTitle: "Timeline",
          textDescription:
            "Move-in Date: Sunday, June 2nd 2024, Move-out Date: Friday, August 23rd 2024",
        },
        {
          textTitle: "Utilities",
          textDescription:
            "All included! Water, trash, recycling, electric, and gigabit wifi.",
        },
      ],
    },
    {
      buttonHeader: "Rates",
      buttonText: [
        {
          textTitle: "",
          textNode: (
            <p className={styles.textDescription}>
              Rates are for the entire summer (
              {
                <a
                  href={APPLICATION_LINK}
                  target="_blank"
                >
                  Apply Now
                </a>
              }
              )
            </p>
          ),
          // textDescription: "Rates are for the entire summer",
        },
        {
          textTitle: "Single: $6,450",
          textDescription: "A large room for one!",
        },
        {
          textTitle: "Split-Single: $5,150",
          textDescription:
            "Two singles connected to each other. Shared with one other person.",
        },
        {
          textTitle: "Triple: $3,500",
          textDescription:
            "Consists of an inner room for sleeping and an outer room for studying and socializing with roommates!",
        },
      ],
    },
  ];
  return (
    <Container fluid id="summer">
      <Row>
        <Col xs={{ span: 10, offset: 1 }} className={styles.sectionCol}>
          <p className={styles.sectionTitle}>SUMMER HOUSING </p>
          <p className={styles.textTitle}>
            <a
              href={APPLICATION_LINK}
              target="_blank"
            >
              Apply Now
            </a>
          </p>

          <Accordion className={styles.accordion}>
            {accordion.map((button, idx1) => {
              return (
                <Accordion.Item
                  key={`summer-housing-dropdown-item-${idx1}`}
                  eventKey={idx1.toString(10)}
                >
                  <Accordion.Header className={styles.accordionButtonHeader}>
                    {button.buttonHeader}
                  </Accordion.Header>
                  <Accordion.Body>
                    {button.buttonText.map((textSection, idx2) => {
                      return (
                        <div key={`summer-housing-dropdown-item-body-${idx2}`}>
                          <strong className={styles.textTitle}>
                            {textSection.textTitle}
                          </strong>
                          {textSection.textDescription ? (
                            <p className={styles.textDescription}>
                              {textSection.textDescription}
                            </p>
                          ) : (
                            textSection.textNode
                          )}
                        </div>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default SummerHousingDropdown;
