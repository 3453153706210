import styles from '../../styles/HistoryPage/TimelineCard.module.css';

export interface TimelineItem {
    date: string,
    cardTitle: string,
    titleUrl?: string,
    descriptionText?: string,
    descriptionElement?: JSX.Element,
    timelineIcon?: JSX.Element,
}

const TimelineCard = ({card}: {card: TimelineItem}) => {
    return (
        <div className={styles.timelineItemContainer}>
            <div className={styles.timelineItemContent}>
                <a
                    href={card?.titleUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.contentTitle}
                >
                    {card.cardTitle}
                </a>
                <time>[{card.date}]</time>
                <p className={styles.contentDescription}>{card.descriptionText}</p>
                <span className={styles.timelineIcon}>{card.timelineIcon}</span>
            </div>
        </div>
    )
}

export default TimelineCard;