/*
  Creator: Rom Fradkin
  Email: fradkin.rom@gmail.com
  Date: June 28, 2023

  Description: Functions that use cookies that are used across
  many pages
*/
function getPersistentDynamicId(): string[] {
    let persistentId: string | undefined
    let dynamicId: string | undefined
  
    for (const cookie of document.cookie.split(';')) {
        const [name, value] = cookie.trim().split('=')
        if (name === 'brothers-only-access') {
            const cookieValues: string[] = value.split('|')
            if (cookieValues.length === 2) {
                [persistentId, dynamicId] = cookieValues
            }
            break
        }
    }
  
    if (persistentId === undefined || dynamicId === undefined) {
        throw new Error('Cookie not found')
    }

    return [persistentId, dynamicId]
  }
  

export default getPersistentDynamicId