import {useEffect} from 'react';

// Components
import TextButtonImageSection from '../components/TextButtonImageSection';
import ImpactImageCardSection from '../components/ImpactPage/ImpactImageCardSection';

// Images
import newmacChampionBannerAndHammer_jpg from '../assets/ImpactPage/IMG_9450.jpg';
import gameOnRoof_jpg from '../assets/ImpactPage/000051300002.jpg';

const ImpactPage = () => {
    useEffect(() => {
        document.title = 'Impact'
     }, []);

    return (
        <div>
            <TextButtonImageSection config={{
                imgSrc: gameOnRoof_jpg,
                minHeight: '50vh',
                imgConfig: {tintColor: '#353631', tintOpacity: 0.3, attachment: ''},
                headerText: 'WHAT WE DO',
            }}/>
            <ImpactImageCardSection/>
        </div>
    )
}

export default ImpactPage;