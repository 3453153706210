// Images
import christianWilliams_jpeg from '../../assets/ImpactPage/CardImages/christian.jpeg';
import finaryTeam_jpeg from '../../assets/ImpactPage/CardImages/finary_people.jpeg';
import c16Biosciences_jpeg from '../../assets/ImpactPage/CardImages/c16.jpeg';
import danielCarballo_jpeg from '../../assets/ImpactPage/CardImages/carballo.jpeg';
import ministryOfSupply_jpg from '../../assets/ImpactPage/CardImages/ministryofsupply.jpg';
import vicLiuTravellerPack_jpeg from '../../assets/ImpactPage/CardImages/travelerpack.jpeg';
import footballNewmac_jpg from '../../assets/ImpactPage/CardImages/nathan.jpg';
import theoStFrancisBooj_jpg from '../../assets/ImpactPage/CardImages/Zebra.jpg';
import klarityTeam_jpg from '../../assets/ImpactPage/CardImages/klarity.jpg';
import ringDeliver_jpg from '../../assets/ImpactPage/CardImages/ring_delivery_green.jpg';

// Components
import FlippyCardSection, { CardInfo } from '../FlippyCardSection';

const ImpactImageCardSection = () => {
    const cards: CardInfo[][] = [
        [
            {
                img_src: ringDeliver_jpg,
                card_title: 'Ring Committee',
                card_description: 'Brothers Jens \'23 and Sullivan \'23 served on MIT\' ring committee; a group of students selected at the conclusion of their freshman year to design a custom class ring which is presented to their peers at the end of their sophomore year.'
            },
            {
                img_src: finaryTeam_jpeg,
                card_title: 'Finary',
                card_description: 'Phifer \'22 and Bhathena \'20 co-founded Finary, which is "Discord for Investing." They raised $3.2 million in a 2021 seed round.'
            },
            {
                img_src: vicLiuTravellerPack_jpeg,
                card_title: 'International Non-Profit',
                card_description: 'Liu \'20 designed a sleeping bag-blanket hybrid, TravelerPack, for Syrian refugees. He tested his prototype on the roof of PBE, and before long, 300 sleeping bags were shipped overseas.'
            },
        ],
        [
            {
                img_src: danielCarballo_jpeg,
                card_title: 'Encora Therapeutics',
                card_description: 'Carballo \'17 co-founded Encora Therapeutics, which created an AI-powered wrist device that reduces tremors. They raised $1.5 million in a 2020 pre-seed round.'
            },
            {
                img_src: ministryOfSupply_jpg,
                card_title: 'Athletic Clothing Startup',
                card_description: 'Rustagi \'11 co-founded Ministry of Supply, a high-tech fashion company focused on performance professional clothing. They\'ve raised over $9.2 million as of 2018. '
            },
            {
                img_src: christianWilliams_jpeg,
                card_title: 'Christian Williams',
                card_description: 'Williams \'20 was selected as a 2020 Fulbright Fellow, for which he conducted computer vision research at ENS Paris-Saclay in France.'
            },
        ],
        [
            {
                img_src: c16Biosciences_jpeg,
                card_title: 'C16 Biosciences',
                card_description: 'Heller \'18 co-founded C16 Biosciences, which makes synthetic palm oil. They raised $20 million in their 2020 Series A.'
            },
            {
                img_src: theoStFrancisBooj_jpg,
                card_title: 'Neuro-rehabilitation author',
                card_description: 'St. Francis \'22 co-authored a book on neuro-rehabilitaton after a spinal cord injury. As the first of its kind to apply principles of human motor development & neuroplasticity, the book serves as a recovery guide for the 15,000 people annually who suffer paralyzing injuries.'
            },
            {
                img_src: klarityTeam_jpg,
                card_title: 'Klarity',
                card_description: 'Nadhamuni \'16 and Ford \'18 co-founded Klarity, which uses AI to automate review of law documents.'
            },
        ]
    ]
    return (
        <FlippyCardSection section_title='Brothers of PBE devote time to startups, community service, athletics, research, and more. Hover over the images below for a bit on what we have been up to!' cards_matrix={cards}/>
    )
}

export default ImpactImageCardSection;