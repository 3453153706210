import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import TextButtonImageSection from '../components/TextButtonImageSection';
import Access from '../components/BrothersOnlyAccessPage/Access';
// import TextLinkSection from "../components/TextLinkSection";
// import React, { useState, useRef, FormEvent } from 'react';

// Images
import big_little_jpeg from '../assets/BrothersOnlyAccessPage/big_little.jpeg';

const BrothersOnlyAccess = () => {
    const location1 = useLocation();
    var endpoint: string = location1.state?.endpoint || '/brothersOnly'
    // const [location, setLocation] = useState('');
    // setLocation(endpoint)
    // console.log('**')
    // console.log(endpoin1t)
    // console.log({endpoint1})
    // console.log('here')
    useEffect(() => {
        document.title = 'Brothers Only Access'
        // console.log(document.referrer)
     }, []);


    return (
        <div style={{overflow: 'hidden'}}>
            <TextButtonImageSection config={{
                    imgSrc: big_little_jpeg,
                    minHeight: '45vh',
                    // imgConfig: {tintColor: '#353631', tintOpacity: 0.3, attachment: 'fixed', positionY: '35%'},
                    headerText: 'BROTHERS ONLY',
                }}/>
            {/* <TextLinkSection 
                sectionText="A place to connect with brothers"
                paddingTop={5}
                paddingBottom={0.8}
            /> */}
            <Access endpoint={endpoint}/>
        </div>
    )
}

export default BrothersOnlyAccess;