import dupontDoubleImageBlackWhite_jpg from '../../assets/HistoryPage/dupont-491x564.jpg';
import TextButtonImageSection from '../../components/TextButtonImageSection';
import styles from '../../styles/HistoryPage/Timeline.module.css';
import TimelineCard, { TimelineItem } from './TimelineCard';


const Timeline = () => {
    const timelineCards: TimelineItem[] = [
    {
        date: '1890',
        cardTitle: 'PHI BETA EPSILON FOUNDED',
        descriptionText: 'Phi Beta Epsilon was founded as a local fraternity, for the purpose of creating and maintaining among technology men a better and stronger interest in literary, scientific, and social pursuits.',
      },
      {
        date: '1917',
        cardTitle: 'THE MOVE TO "OL\' 400"',
        descriptionText: 'After living in 7 different houses around Boston over a span of 27 years, Phi Beta Epsilon brothers moved into the address of our current, beloved home: 400 Memorial Drive.',
      },
      {
        date: '1959',
        cardTitle: 'duPont ATHLETIC FACILITY OPENS',
        titleUrl: 'https://www.mitathletics.com/information/facilities/duPont',
        descriptionText: 'Aided by the vision and donation of Brother David Flett duPont \'56, this building still serves as the home to many MIT sports facilities, such as basketball, volleyball, fencing, rifle, squash, fancing, and gymnastics.',
      },
      {
        date: '1971',
        cardTitle: 'J.B. CARR INDOOR TENNIS FACILITY',
        titleUrl: 'https://img.newspapers.com/clip/31578558/carr-jb-donates-indoor-and-outdoor/',
        descriptionText: 'Donated by Brother J.B. Carr \'16, the tennis bubble provides the first year-round tennis facility for MIT student-athletes as well as other members of the community.',
      },
      {
        date: '2002',
        cardTitle: 'ZESIGER FITNESS CENTER OPENS',
        titleUrl: 'https://www.mitathletics.com/information/facilities/zcenter',
        descriptionText: 'Donated by Brother Al Zesiger \'51 and his wife Barrie, this MIT sports and fitness center has been considered an architectural marvel and was honored as one of 10 national Facility of Merit Winners at the 22nd Annual Athletic Business Conference.',
      },
      {
        date: '2013',
        cardTitle: 'BEST NEW MEMBER EDUCATION',
        titleUrl: 'https://www.businesswire.com/news/home/20130804005029/en/Phi-Beta-Epsilon-Fraternity-MIT-Cited-New',
        descriptionText: 'The Phi Beta Epsilon undergraduate body was cited by the MIT Interfraternity Council for having the best New Member Education among MIT fraternities at the IFC\'s awards convocation. Check out our open-source NME handbook HERE!',
      },
      {
        date: '2015',
        cardTitle: 'HOUSE RENOVATION COMPLETE',
        titleUrl: 'https://www.epsteinjoslin.com/phi-beta-epsilon-mit',
        descriptionText: 'After housing brothers for almost 90 years, 400 Memorial Drive was due for an upgrade. This renovation included the addition of an elevator, glass-enclosed lounges, and a brand new roof deck overlooking the Boston skyline and MIT\'s campus.',
      },
      {
        date: '2016',
        cardTitle: 'MIT HONORS REID WEEDON',
        titleUrl: 'http://web.mit.edu/irdf/prs/Weedon-IRDF-article.pdf',
        descriptionText: 'After committing a lifetime of service to PBE, the MIT community, and the world, Brother Reid Weedon \'41 passed away at the age of 96. He helped to found the IRDF and participated in nearly every leadership position at the Institute.',
      },
    ]

    return (
        <TextButtonImageSection config={{
            imgSrc: dupontDoubleImageBlackWhite_jpg,
            minHeight: '100vh',
            sectionWidth: {
                xl: {span: 10, offset: 1}
            },
            imgConfig: {tintColor: '#373737', tintOpacity: 0.75, positionY: '0%'},
            headerText: 'PBE TIMELINE',
            jsxElement: 
                <div className={styles.timelineContainer}>
                    {timelineCards.map((item, idx) => {
                        return (
                            <TimelineCard card={item} key={idx}/>
                        )
                    })}
                </div>
        }}/>
    )
}

export default Timeline;