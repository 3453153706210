/*
  Creator: Rom Fradkin
  Email: fradkin.rom@gmail.com
  Date: June 28, 2023

  Description: Component that holds a button to access the recommendation
*/

// Styles
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import styles from '../../styles/BrothersOnlyPage/Recommendation.module.css'

interface RecommendationProps {
    link: string;
    firstName: string;
}  

const Recommendation = ({ link, firstName, }: RecommendationProps)  => {
    return (
        <Container className={`d-flex justify-content-center align-items-center`}>
            <Col xs="auto" className={`w-75`}>
                <a href={link} target="_blank" rel="noreferrer">
                    <Button
                        className={`${styles.button} w-100`}
                        variant="primary"
                        size="lg"
                        type="submit"
                    >
                        {`Click here for a recommendation from ${firstName}`}
                    </Button>
                </a>
            </Col>
        </Container>
    )
}

export default Recommendation