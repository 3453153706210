import {useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import styles from '../styles/CareersPage/CareersPage.module.css';

// Components
import TextButtonImageSection from '../components/TextButtonImageSection';

// Images
import bigLittleMagianosClass22_jpeg from '../assets/CareersPage/IMG_8284.jpg';
import bigInvTuxesOnRoofClass23_jpg from '../assets/CareersPage/000092330017.jpg';
//
import amazon_png from '../assets/CareersPage/Companies/amazon.png';
import apple_png from '../assets/CareersPage/Companies/apple.png';
import bain_png from '../assets/CareersPage/Companies/bain.png';
import bcg_png from '../assets/CareersPage/Companies/bcg.png';
import blackstone_png from '../assets/CareersPage/Companies/blackstone.png';
import bmw_jpeg from '../assets/CareersPage/Companies/bmw.jpeg';
import boeing_png from '../assets/CareersPage/Companies/boeing.png';
import bofa_png from '../assets/CareersPage/Companies/bofa.png';
import citadel_png from '../assets/CareersPage/Companies/citadel.png';
import citi_png from '../assets/CareersPage/Companies/citi.png';
import creditsuisse_png from '../assets/CareersPage/Companies/creditsuisse.png';
import deptDefense_jpeg from '../assets/CareersPage/Companies/dept_defense.jpeg';
import deshaw_png from '../assets/CareersPage/Companies/deshaw.png';
import evercore_jpeg from '../assets/CareersPage/Companies/evercore.jpeg';
import facebook_png from '../assets/CareersPage/Companies/facebook.png';
import goldman_png from '../assets/CareersPage/Companies/goldman.png';
import google_png from '../assets/CareersPage/Companies/google.png';
import harvardLaw_jpeg from '../assets/CareersPage/Companies/harvard_law.jpeg';
import instagram_jpeg from '../assets/CareersPage/Companies/instagram.jpeg';
import intuitiveSurgical_png from '../assets/CareersPage/Companies/intuitive_surgical.png';
import jandj_png from '../assets/CareersPage/Companies/jandj.png';
import janest_png from '../assets/CareersPage/Companies/janest.png';
import jhmedicine_png from '../assets/CareersPage/Companies/jhmedicine.png';
import jpmorgan_png from '../assets/CareersPage/Companies/jpmorgan.png';
import jump_png from '../assets/CareersPage/Companies/jump.png';
import mckinsey_png from '../assets/CareersPage/Companies/mckinsey.png';
import microsoft_png from '../assets/CareersPage/Companies/microsoft.png';
import ms_jpeg from '../assets/CareersPage/Companies/ms.jpeg';
import nasa_png from '../assets/CareersPage/Companies/nasa.png';
import nference_png from '../assets/CareersPage/Companies/nference.png';
import northrup_png from '../assets/CareersPage/Companies/northrup_g.png';
import nsa_png from '../assets/CareersPage/Companies/nsa.png';
import oracle_png from '../assets/CareersPage/Companies/oracle.png';
import pfizer_png from '../assets/CareersPage/Companies/pfizer.png';
import pjsol_png from '../assets/CareersPage/Companies/pjsol.png';
import pjt_png from '../assets/CareersPage/Companies/pjt.png';
import point72_png from '../assets/CareersPage/Companies/point72.png';
import reddit_png from '../assets/CareersPage/Companies/reddit.png';
import salesforce_png from '../assets/CareersPage/Companies/salesforce.png';
import scaleai_png from '../assets/CareersPage/Companies/scaleai.png';
import spacex_png from '../assets/CareersPage/Companies/spacex.png';
import susq_png from '../assets/CareersPage/Companies/susq.png';
import tesla_png from '../assets/CareersPage/Companies/tesla.png';
import tinder_png from '../assets/CareersPage/Companies/tinder.png';
import uber_png from '../assets/CareersPage/Companies/uber.png';
import united_png from '../assets/CareersPage/Companies/united.png';
import usArmy_jpeg from '../assets/CareersPage/Companies/army.jpeg';

const CareersPage = () => {
    useEffect(() => {
        document.title = 'Careers'
     }, []);

    return (
        <div style={{overflow: 'hidden'}}>
            <TextButtonImageSection config={{
                imgSrc: bigInvTuxesOnRoofClass23_jpg,
                minHeight: '50vh',
                imgConfig: {tintColor: '#353631', tintOpacity: 0.3, attachment: 'fixed', positionY: '100%'},
                headerText: 'WHERE WE WORK',
            }}/>
            <Container fluid>
                    <Col xs={12} lg={{span: 10, offset: 1}} className={styles.sectionCol}>
                        <p className={styles.sectionText}>Brothers of PBE have gone on to work at these prestigious firms.</p>
                        <Image className={styles.image} src={amazon_png} />
                        <Image className={styles.image} src={apple_png} />
                        <Image className={styles.image} src={bain_png} />
                        <Image className={styles.image} src={bcg_png} />
                        <Image className={styles.image} src={blackstone_png} />
                        <Image className={styles.image} src={bmw_jpeg} />
                        <Image className={styles.image} src={boeing_png} />
                        <Image className={styles.image} src={bofa_png} />
                        <Image className={styles.image} src={citadel_png} />
                        <Image className={styles.image} src={citi_png} />
                        <Image className={styles.image} src={creditsuisse_png} />
                        <Image className={styles.image} src={deptDefense_jpeg} />
                        <Image className={styles.image} src={deshaw_png} />
                        <Image className={styles.image} src={evercore_jpeg} />
                        <Image className={styles.image} src={facebook_png} />
                        <Image className={styles.image} src={goldman_png} />
                        <Image className={styles.image} src={google_png} />
                        <Image className={styles.image} src={harvardLaw_jpeg} />
                        <Image className={styles.image} src={instagram_jpeg} />
                        <Image className={styles.image} src={intuitiveSurgical_png} />
                        <Image className={styles.image} src={jandj_png} />
                        <Image className={styles.image} src={janest_png} />
                        <Image className={styles.image} src={jhmedicine_png} />
                        <Image className={styles.image} src={jpmorgan_png} />
                        <Image className={styles.image} src={jump_png} />
                        <Image className={styles.image} src={mckinsey_png} />
                        <Image className={styles.image} src={microsoft_png} />
                        <Image className={styles.image} src={ms_jpeg} />
                        <Image className={styles.image} src={nasa_png} />
                        <Image className={styles.image} src={nference_png} />
                        <Image className={styles.image} src={northrup_png} />
                        <Image className={styles.image} src={nsa_png} />
                        <Image className={styles.image} src={oracle_png} />
                        <Image className={styles.image} src={pfizer_png} />
                        <Image className={styles.image} src={pjsol_png} />
                        <Image className={styles.image} src={pjt_png} />
                        <Image className={styles.image} src={point72_png} />
                        <Image className={styles.image} src={reddit_png} />
                        <Image className={styles.image} src={salesforce_png} />
                        <Image className={styles.image} src={scaleai_png} />
                        <Image className={styles.image} src={spacex_png} />
                        <Image className={styles.image} src={susq_png} />
                        <Image className={styles.image} src={tesla_png} />
                        <Image className={styles.image} src={tinder_png} />
                        <Image className={styles.image} src={uber_png} />
                        <Image className={styles.image} src={united_png} />
                        <Image className={styles.image} src={usArmy_jpeg} />
                    </Col>
            </Container>
        </div>
    )
}

export default CareersPage;