import Flippy, { FrontSide, BackSide } from 'react-flippy';
import styles from '../styles/FlippyCard.module.css';

const FlippyCard = ({img_src, card_title, card_description} : {img_src: string, card_title: string, card_description: string}) => {
    return (
        <Flippy
            flipOnHover={true}
            flipDirection="horizontal"
            className={styles.flippyContainer}
        >
            <FrontSide className={styles.flippyCardFront}>
                <img src={img_src} className={styles.flippyImage}/>
            </FrontSide>
            <BackSide className={styles.flippyCardBack}>
                <p className={styles.cardTitle}>
                    {card_title}
                </p>
                <p className={styles.cardDescription}>
                    {card_description}
                </p>
            </BackSide>
        </Flippy>
    )
}

export default FlippyCard;