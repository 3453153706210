import { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import triangle_png from "../assets/triangle-228x123.png";
import styles from "../styles/NavigationBar.module.css";

const NavigationBar = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0); // Used to change navbar background as user scrolls
  const [navCollapsed, setNavCollapsed] = useState<boolean>(true); // Used to make navbar background dark when opening toggle menu

  // Records position of browser window
  const handleScroll = (): void => {
    const position: number = window.pageYOffset;
    setScrollPosition(position);
  };

  // Updates position variable as user scrolls
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    // Documentation on react bootstrap navbar: https://react-bootstrap.github.io/components/navbar/#api
    <Navbar
      fixed="top"
      expand="xl"
      style={{
        backgroundColor:
          scrollPosition <= 0 && navCollapsed ? "transparent" : "#2d3a47",
        transition: "0.3s ease"
      }}
    >
      {/* Triangle logo and PBE text in left corner of navbar */}
      <div className={styles.logoBrandDiv}>
        <img src={triangle_png} className={styles.navLogo} />
        <Navbar.Brand href="/" className={styles.navBrand}>
          PHI BETA EPSILON
        </Navbar.Brand>
      </div>

      {/* Hamburger toggle menu when navbar links collapse due to screen size being too small */}
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        className={`${styles.navToggleDiv} ${
          navCollapsed ? "" : styles.toggle
        }`}
        onClick={() => setNavCollapsed(!navCollapsed)}
      >
        <div className={`${styles.navToggleLine} ${styles.line1}`} />
        <div className={`${styles.navToggleLine} ${styles.line2}`} />
        <div className={`${styles.navToggleLine} ${styles.line3}`} />
        <div className={`${styles.navToggleLine} ${styles.line4}`} />
      </Navbar.Toggle>

      {/* Navbar links to other pages, ie brothers, house, etc */}
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className={styles.navLinksDiv}>
          <Nav.Link href="/brothers" className={styles.navLink}>
            BROTHERS
          </Nav.Link>
          <Nav.Link href="/house" className={styles.navLink}>
            HOUSE
          </Nav.Link>
          <Nav.Link href="/rush" className={styles.navLink}>
            {/* Change to `RUSH` or `CPW` depending on the semester. Must also change the href above, and the route in routes.tsx*/}
            RUSH
          </Nav.Link>
          {/* <Nav.Link href="/careers" className={styles.navLink}>
            CAREERS
          </Nav.Link> */}
          {/* I (Rom) am removing the impact page because
          it is not updated and kind of useless. Feel free to
          update and reincorporate into website in future */}
          {/* <Nav.Link href="/impact" className={styles.navLink}>
            IMPACT
          </Nav.Link> */}
          <Nav.Link href="/history" className={styles.navLink}>
            HISTORY
          </Nav.Link>
          <Nav.Link href="/connect" className={styles.navLink}>
            CONNECT
          </Nav.Link>
          <Nav.Link href="/familytree" target="_blank" className={styles.navLink}>
            TREE
          </Nav.Link>
          <Nav.Link
            href="https://www.instagram.com/phibetaepsilon/"
            target="_blank"
            className={styles.navLink}
          >
            <BsInstagram />
          </Nav.Link>
          <Nav.Link
            href="https://www.facebook.com/PhiBetaEpsilon/"
            target="_blank"
            className={styles.navLink}
          >
            <FaFacebookF />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
