/*
  Creator: Rom Fradkin
  Email: fradkin.rom@gmail.com
  Date: July 9, 2023

  Description: This code blocks access to restricted parts of pbe.mit.edu. This
  is only ui/ux blocking. If someone is determined enough, they could access the 
  html pages that are protected by this element, but not neccessarily the data.
  They would need to access the server to also access the data. 
*/

import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const PrivateRoutes = ({ requireActive }: { requireActive: boolean }) => {
    const [isRequesting, setIsRequesting] = useState<boolean>(true)
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [isActive, setIsActive] = useState<boolean>(false)
    var endpoint: string = useLocation().pathname
    console.log("ENDPOINT: ", endpoint)
    useEffect(() => {
        const cookies: string[] = document.cookie.split(';')
        let persistentId: string = ''
        let dynamicId: string = ''

        for (const cookie of cookies) {
            const [name, value] = cookie.trim().split('=')
            if (name === 'brothers-only-access') {
                const cookieValues = value.split('|')
                if (cookieValues.length === 2) {
                    [persistentId, dynamicId] = cookieValues
                }
                break
            }
        }

        fetch('https://phibetaepsilon.org/verify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ persistentId: persistentId, dynamicId: dynamicId })
        })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else if (response.status === 401) {
                setIsRequesting(false)
                throw new Error('Unauthorized')
            } else {
                throw new Error('Error: ' + response.status);
            }
        })
        .then((data) => {
            setIsActive(data.active === 1)
            setIsAuthenticated(true)
            setIsRequesting(false)
        })
        .catch((error) => {
            console.error(error)
            setIsRequesting(false)
        })
    }, [])

    //  Render a null page if the request is still loading
    if (isRequesting) {
        console.log("RESPONSE REQUESTING")
        return <div style={{ height: '1000px', backgroundColor: "#2d3a47" }}></div> 
    } else {
        if (isAuthenticated) {
            if ((!requireActive) || (requireActive && isActive)) {
                return <Outlet/>
            } else {
                return <Navigate to="/connect"/>
            }
        } else {
            return <Navigate to="/brothersOnlyAccess" state={{ endpoint: endpoint }}/>
        }
    }
}

export default PrivateRoutes;
