import Card from "react-bootstrap/Card";
// import BrotherCardImage from "./BrotherCardImage";
import styles from "../../styles/BrothersPage/BrotherCard.module.css";
// import { type } from "os";

interface BrotherInfo {
  class_year: string;
  hometown?: string;
  major?: string;
  interests?: string;
  position?: string;
}

// {
//   /* Transforms string from "<word>_<word>_..." into "<Word> <Word> ..." */
// }
const capitalizeFirstLetters = (first_last: string): string => {
  return first_last
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase().concat(word.substring(1)))
    .join(" ");
};

const BrotherCard = ({
  first_last,
  brotherInfo,
}: {
  first_last: string;
  brotherInfo: BrotherInfo;
}) => {
  const importAll = (r: __WebpackModuleApi.RequireContext) => {
    // return r.keys().map(r);
    var output: string[] = [];
    for (var e in r.keys().map(r)) {
      if (typeof e === "string") {
        output.concat(e);
      }
    }
    return output;
  };

  var images: string[];
  // Import from folder
  try {
    images = importAll(
      require.context(
        `../../assets/BrothersPage/BrotherImages/${brotherInfo.class_year}/${first_last}`,
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
  } catch (e) {
    // Import single image not in folder
    try {
      images = [
        require(`../../assets/BrothersPage/BrotherImages/${brotherInfo.class_year}/${first_last}.jpg`),
      ];
      // Use default image
    } catch (e) {
      images = [
        require("../../assets/BrothersPage/BrotherImages/default.jpeg"),
      ];
    }
  }

  return (
    <Card className={styles.cardContainer}>
      <Card.Img variant="top" src={images[0]} />
      {/* <BrotherCardImage images={images} /> */}
      {brotherInfo.position ? (
        <div className={styles.positionFlair}>
          <p>{brotherInfo.position}</p>
        </div>
      ) : (
        <></>
      )}
      <Card.Body style={{ position: "relative" }}>
        <Card.Title>{capitalizeFirstLetters(first_last)}</Card.Title>
        <Card.Text>
          <strong className={styles.infoHeader}>Hometown:</strong>
          <p className={styles.infoDescription}>{brotherInfo.hometown}</p>
          <strong className={styles.infoHeader}>Major:</strong>
          <p className={styles.infoDescription}>{brotherInfo.major}</p>
          <strong className={styles.infoHeader}>Interests:</strong>
          <p className={styles.infoDescription}>{brotherInfo.interests}</p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default BrotherCard;
