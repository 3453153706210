const PhpMyAdminPage = () => {
    window.location.href = 'https://phibetaepsilon.org/phpmyadmin'

    return (
        <>
            {null}
        </>
    )
}

export default PhpMyAdminPage;