import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from '../styles/TextInfoSection.module.css';

const TextInfoSection = ({sectionText}: {sectionText: string}) => {
    return (
        <Container fluid>
            <Row className={styles.sectionRow}>
                <Col xs={{span: 12}} md={{span: 10, offset: 1}} className={styles.sectionCol}>
                    <hr className={styles.horizontalLine} />
                    <p className={styles.sectionText}>{sectionText}</p>
                    <hr className={styles.horizontalLine} />
                </Col>
            </Row>
        </Container>
    )
}

export default TextInfoSection;