/*
  Creator: Rom Fradkin
  Email: fradkin.rom@gmail.com
  Date: June 28, 2023

  Description: Component that submits links to the server
*/

// Functionality
import axios from 'axios'
import { useState, FormEvent, ChangeEvent, Dispatch, SetStateAction } from 'react'

// Styles
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import styles from '../../styles/BrothersOnlyPage/Submissions.module.css'

// Utils
import getPersistentDynamicId from '../../utils/cookieFunctions'

const Submissions = () => {

    const [link, setLink] = useState('')
    const [persistentId, _] = getPersistentDynamicId()

    const inputChangeHandler = (setFunction: Dispatch<SetStateAction<string>>, 
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFunction(event.target.value)
    }

    const isValidLink = (text: string) => {
        // Regular expression for a basic URL validation
        const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;%=]*)?$/
        // Test if the provided text matches the URL pattern
        return urlPattern.test(text)
    }

    const handleSubmission = (event: FormEvent): void => {
        event.preventDefault(); // Prevent default form submission

        if (!isValidLink(link)) {
            // Add some red or something to say invalid instead of alert
            // am just doing alert cause I am lazy
            // maybe even add some filtering to make sure it is an adult link
            // instead of just a website, but again, I am lazy
            // - Rom 
            alert('Error, invalid link (next N make this a better error)')
            return
        }
    
        axios.post('https://phibetaepsilon.org/submitLink', {
            link: link,
            persistentId: persistentId
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            if (response.status === 200) {
                // Next N make this better
                alert("thanks for the rec")
                window.location.href = '/brothersOnly';
            } else {
                throw new Error('Error: ' + response.status);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        })
    }

    return (
        <Container fluid className='d-flex justify-content-center align-items-center'>
            <Col xs={11} sm={8} md={8} lg={9} xl={9} className={`${styles.form} mt-3 mb-3`}>
                <Form className='text-center' onSubmit={handleSubmission}>
                    <h1 className={`${styles.linkTitle} mt-3 mb-3`}>
                        {/* Maybe store sentence this on server as to not have it possible for
                        anyone to know that you are inputing porn without server access... */}
                        {/* Future N security issues -Rom */}
                        {'Enter A Porn Recommendation'}
                    </h1>
                    <Form.Group className='d-flex align-items-center justify-content-center'>
                        <Form.Control
                            className={`${styles.input} ${styles.inputLinks} pl-2 mb-3`}
                            as='input'
                            placeholder='https://xhamster.com/videos/hot-cum-on-wife-s-face-oksianal-xhgvQU1'
                            onChange={(e) => inputChangeHandler(setLink, e)}
                        />
                    </Form.Group>
                    <Button
                        className={`${styles.submit} mb-4`}
                        variant='primary'
                        size='lg'
                        type='submit'
                    >
                        {'𓂸'}
                    </Button>
                </Form>
            </Col>
        </Container>
    )
}

export default Submissions