import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from '../styles/FlippyCardSection.module.css';

// Components
import FlippyCard from './FlippyCard';

export interface CardInfo {
    img_src: string,
    card_title: string,
    card_description: string
}

const FlippyCardSection = ({section_title, cards_matrix}: {section_title: string, cards_matrix: CardInfo[][]}) => {
    return (
        <Container fluid>
            <Row>
                <Col xs={{span: 10, offset: 1}} className={styles.sectionCol}>
                    <p className={styles.sectionText}>
                        {section_title}
                    </p>
                </Col>
            </Row>

            {cards_matrix.map((cards_row, idx1) => {
                return (
                    <Row key={`${section_title}-${cards_matrix.length}-${idx1}`} className={styles.cardRow}>
                        {cards_row.map((card, idx2) => {
                            return (
                                <Col key={`${section_title}-${cards_matrix.length}-${idx1}-col-${idx2}`} xs='auto' className={styles.cardCol}>
                                    <FlippyCard img_src={card.img_src} card_title={card.card_title} card_description={card.card_description}/>
                                </Col>
                            )
                        })}
                    </Row>
                )
            })}
        </Container>
    )
}

export default FlippyCardSection;