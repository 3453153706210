import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import TextButtonImageSection from "../TextButtonImageSection";
import { configKeys } from "../../config";
import styles from "../../styles/RushPage/EventScheduleTable.module.css";

// Images
import rooftopDinner_jpg from "../../assets/RushPage/phi-beta-epsilon-mit-epstein-joslin-6-1376x983.jpg";

interface IEventDate {
  date: string;
  event: { name: string | JSX.Element; time: string | JSX.Element; location: string | JSX.Element }[];
}

const EventScheduleTable = () => {
  const eventDates: IEventDate[] = [
    {
      date: "SATURDAY 8/31",
      event: [
        {
          name: "PBE House Tours + Insomnia Cookies",
          time: "1:00 PM - 4:00 PM",
          location: "400 Memorial Dr."
        },
        {
          name: "Cane's Chicken and Buttermilk Waffles",
          time: "6:30 PM - 8:00 PM",
          location: "400 Memorial Dr."
        },
        {
          // name: 'PBE ROOFTOP PARTY: BAYWATCH',
          name: (
            <p style={{ fontWeight: 900 }}>
              <span className={styles.baywatchEmojis1}>🏄‍♂️</span>
              PBE PRESENTS:{""}
              <span style={{ color: "yellow", margin: "0" }}>BAYWATCH</span>
              <span className={styles.baywatchEmojis2}>🏄‍♂️</span>
            </p>
          ),
          time: "10:00 PM - 1:00 AM",
          location: "400 Memorial Dr."
        },
      ],
    },
    {
      date: "SUNDAY 9/1",
      event: [
        {
          name: "PBE Lake Day: Flyboarding, Jet Skis, Tubing",
          time: "10:45 AM - 4:30 PM",
          location: "400 Memorial Dr."
        },
        {
          name: "FIRE+ICE All You Can Eat Grill",
          time: "6:30 PM - 8:30 PM",
          location: "400 Memorial Dr."
        },
        {
          name: "Late Night Chick-Fil-A",
          time: "11:00 PM - 1:00 AM",
          location: "400 Memorial Dr."
        },
        
      ],
    },
    {
      date: "MONDAY 9/2",
      event: [
        {
          name: "Go Karting",
          time: "10:45 AM - 3:30 PM",
          location: "400 Memorial Dr."
        },
        {
          name: "Rooftop Cookie Hour",
          time: "10:00 PM - 12:00 AM",
          location: "400 Memorial Dr."
        },

      ],
    },
    {
      date: "TUESDAY 9/3",
      event: [
        {
          name: "Barbecue and Beats",
          time: "11:30 AM - 2:00 PM",
          location: "400 Memorial Dr."
        },
        {
          name: "Yamatos All You Can Eat Sushi (Invite Only)",
          time: "7:30 PM - 10:00 PM",
          location: "400 Memorial Dr."
        },
      ],
    },
    {
      date: "WEDNESDAY 9/4",
      event: [
        {
          name: "Top Golf (Invite Only)",
          time: "7:30 PM - 10:00 PM",
          location: "400 Memorial Dr."
        },
      ]
    },
    {
      date: "THURSDAY 9/5",
      event: [
        {
          name: "NFL Opener (Invite Only)",
          time: "8:00 PM - 11:00 PM",
          location: "400 Memorial Dr."
        },
      ]
    }
  ];

  return (
    <TextButtonImageSection
      config={{
        sectionWidth: { xl: { span: 10, offset: 1 } },
        imgSrc: rooftopDinner_jpg,
        imgConfig: {
          tintColor: "#2d3a47",
          tintOpacity: 0.8,
          attachment: "fixed",
          positionY: "50%",
        },
        headerText: `${configKeys.CurrentSemester} RUSH Schedule`,
        jsxElement: (
          <Table borderless hover className={styles.table}>
            <thead>
              <tr className={styles.tableHeaderRow}>
                <th className={`${styles.columnHeaderText} ${styles.startCol}`}>
                  Date
                </th>
                <th>
                  <div
                    className={`${styles.columnHeaderText} ${styles.centerCol}`}
                  >
                    Event
                  </div>
                </th>
                <th>
                  <div
                    className={`${styles.columnHeaderText} ${styles.centerCol}`}
                  >
                    Time
                  </div>
                </th>
                <th>
                  <div
                    className={`${styles.columnHeaderText} ${styles.endCol}`}
                  >
                    Location
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {eventDates.map((day, idx1) => {
                return (
                  <tr
                    key={`rush-page-event-schedule-row-${idx1}`}
                    className={styles.tableRow}
                  >
                    <td className={`${styles.eventText} ${styles.startCol}`}>
                      {day.date}
                    </td>
                    <td>
                      <div
                        className={`${styles.eventText} ${styles.centerCol}`}
                      >
                        {day.event.map((event, idx2) => {
                          return (
                            <div
                              key={`rush-page-event-schedule-row-${idx1}-head-${idx2}`}
                              style={{ margin: "0" }}
                            >
                              {typeof event.name === "string" ? (
                                <p>{event.name}</p>
                              ) : (
                                event.name
                              )}
                            </div>
                            
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <div className={`${styles.eventText} ${styles.centerCol}`}>
                        {day.event.map((event, idx3) => {
                          return (
                            <p
                              key={`rush-page-event-schedule-row-${idx1}-body-${idx3}`}
                            >
                              {event.time}
                            </p>
                          );
                        })}
                      </div>
                    </td>
                    <td>
                      <div className={`${styles.eventText} ${styles.endCol}`}>
                        {day.event.map((event, idx4) => {
                          return (
                            <p
                              key={`rush-page-event-schedule-row-${idx1}-body-${idx4}`}
                            >
                              {event.location}
                            </p>
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ),
      }}
    />
  );
};

// const EventScheduleTable = () => {
//   const eventDates: IEventDate[] = [
//     {
//       date: "THURSDAY APRIL 11th",
//       event: [
//         {
//           name: "PBE House Tours + Insomnia Cookies",
//           time: "1:00 PM - 4:30 PM",
//         },
//         {
//             name: "Chicken and Waffles Dinner",
//             time: "6:30 PM - 8:00 PM",
//         },
//         {
//           // name: 'PBE ROOFTOP PARTY: BAYWATCH',
//           name: (
//             <p style={{ fontWeight: 900 }}>
//               <span className={styles.baywatchEmojis1}>🌃🏝</span>
//               PBE PRESENTS:{" "}
//               <span style={{ color: "yellow", margin: "0" }}>ROOFTOP LUAU</span>
//               <span className={styles.baywatchEmojis2}>🏝🌃</span>
//             </p>
//           ),
//           time: "10:00 PM - 1:00 AM",
//         },
//       ],
//     },
//     {
//       date: "SUNDAY SEPTEMBER 3RD",
//       event: [
//         {
//           name: "Lake Day: Jet Skis, Flyboarding, Tubing",
//           time: "11:00 AM - 5:00 PM",
//         },
//         {
//           name: "Late Night Chick-Fil-A",
//           time: "11:30 PM - 1:00 AM",
//         },
//       ],
//     },
//     {
//       date: "MONDAY SEPTEMBER 4TH",
//       event: [
//         {
//           name: "Go Karting",
//           time: "10:45 AM - 3:30 PM",
//         },
//         {
//             name: "Sunset Steak Dinner",
//             time: "6:30 PM - 8:00 PM",
//         },
//         {
//             name: "Rooftop Cookie Hour",
//             time: "10:00 PM - 12:00 AM",
//         },
//       ],
//     },
//     {
//         date: "TUESDAY SEPTEMBER 5TH",
//         event: [
//           {
//             name: "Barbecue and Beats",
//             time: "1:00 PM - 3:00 PM",
//           },
//           {
//               name: "Fogo De Chau (Invite Only)",
//               time: "7:00 PM - 9:00 PM",
//           },
//         ],
//     },
//     {
//         date: "WEDNESDAY SEPTEMBER 6TH",
//         event: [
//           {
//             name: "Yamatos All You Can Eat Sushi (Invite Only)",
//             time: "7:30 PM - 10:00 PM",
//           },
//         ],
//     },
//     {
//         date: "THURSDAY SEPTEMBER 7TH",
//         event: [
//           {
//             name: "NFL Opener (Invite Only)",
//             time: "8:30 PM - 11:30 PM",
//           },
//         ],
//     },
//     {
//         date: "FRIDAY SEPTEMBER 8TH",
//         event: [
//           {
//             name: "Bid Night: Dessert and Dates (Invite Only)",
//             time: "8:00 PM - 11:00 PM",
//           },
//         ],
//     },
//   ];
//   return (
//     <TextButtonImageSection
//       config={{
//         sectionWidth: { xl: { span: 10, offset: 1 } },
//         imgSrc: rooftopDinner_jpg,
//         imgConfig: {
//           tintColor: "#2d3a47",
//           tintOpacity: 0.8,
//           attachment: "fixed",
//           positionY: "50%",
//         },
//         headerText: `${configKeys.CurrentSemester} Rush Schedule`,
//         jsxElement: (
//           <Table borderless hover className={styles.table}>
//             <thead>
//               <tr className={styles.tableHeaderRow}>
//                 <th className={`${styles.columnHeaderText} ${styles.startCol}`}>
//                   Date
//                 </th>
//                 <th>
//                   <div
//                     className={`${styles.columnHeaderText} ${styles.centerCol}`}
//                   >
//                     Event
//                   </div>
//                 </th>
//                 <th>
//                   <div
//                     className={`${styles.columnHeaderText} ${styles.endCol}`}
//                   >
//                     Time
//                   </div>
//                 </th>
//               </tr>
//             </thead>
//             <tbody className={styles.tableBody}>
//               {eventDates.map((day, idx1) => {
//                 return (
//                   <tr
//                     key={`rush-page-event-schedule-row-${idx1}`}
//                     className={styles.tableRow}
//                   >
//                     <td className={`${styles.eventText} ${styles.startCol}`}>
//                       {day.date}
//                     </td>
//                     <td>
//                       <div
//                         className={`${styles.eventText} ${styles.centerCol}`}
//                       >
//                         {day.event.map((event, idx2) => {
//                           return (
//                             <div
//                               key={`rush-page-event-schedule-row-${idx1}-head-${idx2}`}
//                               style={{ margin: "0" }}
//                             >
//                               {typeof event.name === "string" ? (
//                                 <p>{event.name}</p>
//                               ) : (
//                                 event.name
//                               )}
//                             </div>
//                           );
//                         })}
//                       </div>
//                     </td>
//                     <td>
//                       <div className={`${styles.eventText} ${styles.endCol}`}>
//                         {day.event.map((event, idx3) => {
//                           return (
//                             <p
//                               key={`rush-page-event-schedule-row-${idx1}-body-${idx3}`}
//                             >
//                               {event.time}
//                             </p>
//                           );
//                         })}
//                       </div>
//                     </td>
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </Table>
//         ),
//       }}
//     />
//   );
// };

export default EventScheduleTable;
