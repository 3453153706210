import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from '../styles/TextButtonImageSection.module.css';

interface SectionSizing {
    xs?: {span?: number, offset?: number},
    sm?: {span?: number, offset?: number},
    md?: {span?: number, offset?: number},
    lg?: {span?: number, offset?: number},
    xl?: {span?: number, offset?: number},
    xxl?: {span?: number, offset?: number},
}

interface ImageConfig {
    positionX?: string,
    positionY?: string,
    attachment?: string,
    tintColor?: string,
    tintOpacity?: number,
}

export interface TextButtonImageConfig {
    minHeight?: number | string, // Arg must be in terms of [px, vw, vh]. Using % will not work.
    sectionWidth?: SectionSizing,
    imgSrc: string,
    imgConfig?: ImageConfig,
    headerText: string,
    descriptionText?: string,
    buttonText?: string,
    buttonLink?: string,
    buttonIcon?: JSX.Element,
    sectionTag?: string,
    jsxElement?: JSX.Element,
}

const TextButtonImageSection = ({config} : {config: TextButtonImageConfig}) => {
    const [isMobile, setIsMobile] = useState(true);

    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
      }
      
      // create an event listener
      useEffect(() => {
        // window.addEventListener("resize", handleResize)
        handleResize();
    })

    // Allows custom sizing of this component. Default to xs=12 and xl=8, 2
    const defaultSizng: SectionSizing = {
        xs: config?.sectionWidth?.xs ? config.sectionWidth.xs : {span: 12},
        sm: config?.sectionWidth?.sm ? config.sectionWidth.sm : {},
        md: config?.sectionWidth?.md ? config.sectionWidth.md : {},
        lg: config?.sectionWidth?.lg ? config.sectionWidth.lg : {},
        xl: config?.sectionWidth?.xl ? config.sectionWidth.xl : {span: 8, offset: 2},
        xxl: config?.sectionWidth?.xxl ? config.sectionWidth.xxl : {},
    } 
    return (
        <Container fluid id={config.sectionTag} className={styles.sectionContainer} style={{
            minHeight: config.minHeight ? config.minHeight : '',
            background: `url(${config.imgSrc})`,
            backgroundSize: 'cover',
            backgroundPositionX: config.imgConfig?.positionX ? config.imgConfig.positionX : '50%',
            backgroundPositionY: config.imgConfig?.positionY ? config.imgConfig.positionY : '50%',
            backgroundAttachment: (!isMobile && config.imgConfig?.attachment) ? config.imgConfig.attachment : '',
        }}>
            <div style={{
                height: '100%',
                width: '100%',
                backgroundColor: config.imgConfig?.tintColor ? config.imgConfig.tintColor : "#000000",
                opacity: config.imgConfig?.tintOpacity ? config.imgConfig.tintOpacity : 0.0,
                position: 'absolute',
            }}/>
            <Row className={styles.textRow}>
                <Col xs={defaultSizng?.xs} sm={defaultSizng?.sm} md={defaultSizng?.md} lg={defaultSizng?.lg} xl={defaultSizng?.xl} xxl={defaultSizng?.xxl} className={styles.textCol}>
                    <p className={styles.headerText}>{config.headerText}</p>
                    {config.descriptionText ?
                        <p className={styles.descriptionText}>{config.descriptionText}</p>
                        :
                        <></>
                    }
                    {config?.buttonText ?
                        <a className={styles.buttonContainer} href={config.buttonLink}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {config.buttonIcon ?
                                    <span className={styles.buttonIcon}>
                                        {config?.buttonIcon}
                                    </span>
                                    :
                                    <></>
                                }
                                <p className={styles.buttonText}>{config.buttonText}</p>
                            </div>
                        </a>
                        :
                        <></>
                    }
                    {config.jsxElement ?
                        config.jsxElement
                        :
                        <></>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default TextButtonImageSection;