import React, { useState, useRef, FormEvent } from 'react';
// import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styles from '../../styles/BrothersOnlyAccessPage/Access.module.css';

// Images
import crest_png from '../../assets/BrothersOnlyAccessPage/crest.png';

const Access = ({ endpoint }: { endpoint: string }) => {

    const [signIn, setSignIn] = useState<boolean>(true)
    const [notFilled, setNotFilled] = useState<boolean>()

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [signInValid, setSignInValid] = useState<boolean>(true)
    const [emailValid, setEmailValid] = useState<boolean>(true)
    const [emailFormValid, setEmailFormValid] = useState<boolean>(true)

    const formRef = useRef(null);

    const inputChangeHandler = (setFunction: React.Dispatch<React.SetStateAction<string>>, 
                                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFunction(event.target.value)
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleButtonClick();
      };

    const toggleSignIn = (): void => {
        setFirstName("")
        setLastName("")
        setNotFilled(false)
        setSignIn(!signIn)
        setEmailValid(true);
        setSignInValid(true);
        setEmailFormValid(true);
    }

    // one line
    const checkFormsFilled = (): boolean => {
        if (email !== "" && password !== "") {
            if (signIn || (firstName !== "" && lastName !== "")) {
                return true
            }
        }
        return false
    }

    const isValidEmail = (email: string): boolean => {
        // Regular expression pattern to validate email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
      };

    const handleButtonClick = (): void => {
        setSignInValid(true);
        setEmailValid(true)
        setNotFilled(false);
        setEmailFormValid(true);

        if (!checkFormsFilled()) {
          setNotFilled(true);
          return;
        }
      
        if (signIn) {
          fetch('https://phibetaepsilon.org/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email, password: password })
          })
            .then((response) => {
              // Handle the response from the server
              console.log(response)
              if (response.ok) {
                // Successful response
                // Perform any necessary actions here
                return response.json();
            } else if (response.status === 401) {
                // Invalid email or password
                setSignInValid(false);
                throw new Error('Invalid email or password');
              } else {
                // Error response
                throw new Error('Error: ' + response.status);
              }
            })
            .then((obj) => {
              // Set the cookie with the JSON data
                document.cookie = `${obj.data.name}=${obj.data.value}; \
                                  path=${obj.data.path}; \
                                  max-age=${obj.data.maxAge};`;
                                  
                window.location.href = endpoint;
            })
            .catch((error) => {
              // Handle any errors that occurred during the fetch request
              console.error(error);
            });
        } else {

            if (!isValidEmail(email)) {
                setEmailFormValid(false);
                return; // Exit the function without making the sign-up request
            }

            const requestBody = {
              firstName,
              lastName,
              email,
              password
            };
        
            fetch('https://phibetaepsilon.org/signUp', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(requestBody)
            })
              .then((response) => {
                // Handle the response from the server
                if (response.ok) {
                  // Successful response
                  // Perform any necessary actions here
                    window.location.href = '/awaitVerification'
                // Used if automatically inserting users
                //   return response.json();
                } else if (response.status === 401) {
                    // Invalid email or password
                    setEmailValid(false);
                    throw new Error('Account already associated with this email');
                } else {
                  // Error response
                  throw new Error('Error: ' + response.status);
                }
              })
            // Used if automatically inserting users
            //   .then((obj) => {
            //     // Set the cookie with the JSON data
            //     document.cookie = `${obj.data.name}=${obj.data.value}; \
            //                         path=${obj.data.path}; \
            //                         max-age=${obj.data.maxAge};`;
            //     window.location.href = '/brothersOnly';
                
            //   })
              .catch((error) => {
                // Handle any errors that occurred during the fetch request
                console.error(error);
              });
          }
    };
      
      
      

  return (
    <Container fluid className={`${styles.formContainer} d-flex justify-content-center align-items-center`}>
      <Col xs={12} sm={10} md={8} lg={7} xl={6} className={`${styles.form} text-center mt-5 mb-5`}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <img className={`${styles.crest} mt-4`} src={crest_png} alt="Crest not found" />
          <h1 className={`${styles.linkTitle} text-center mt-2 mb-4`}>{signIn ? 'Sign in' : 'Create an Account'}</h1>

          {!signIn && (
            <Form.Group className="d-flex w-75 justify-content-between mx-auto">
              <Form.Control
                className={`${styles.input} ${styles.inputLinks} ${styles.inputName} mb-1`}
                type="text"
                size="lg"
                placeholder="First Name"
                autoComplete="given-name"
                value={firstName}
                onChange={(e) => inputChangeHandler(setFirstName, e)}
              />
              <Form.Control
                className={`${styles.input} ${styles.inputLinks} ${styles.inputName} mb-1`}
                type="text"
                size="lg"
                placeholder="Last Name"
                autoComplete="family-name"
                value={lastName}
                onChange={(e) => inputChangeHandler(setLastName, e)}
              />
            </Form.Group>
          )}

          <Form.Group className="d-flex align-items-center justify-content-center">
            <Form.Control
              className={`${styles.input} ${styles.inputLinks} w-75 mb-1`}
              type="email"
              size="lg"
              placeholder="Email Address"
              autoComplete="username"
              value={email}
              onChange={(e) => inputChangeHandler(setEmail, e)}
            />
          </Form.Group>
          <Form.Group className="d-flex align-items-center justify-content-center">
            <Form.Control
              className={`${styles.input} ${styles.inputLinks} w-75 mb-2`}
              type="password"
              size="lg"
              placeholder="Password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => inputChangeHandler(setPassword, e)}
            />
          </Form.Group>
          <div className="w-75 mx-auto d-flex justify-content-between mb-3">
            <span className={styles.links} onClick={toggleSignIn}>
              {signIn ? 'Create an Account' : 'Sign in'}
            </span>
          </div>
          <Button
            className={`${styles.submit} ${notFilled ? 'mb-3' : 'mb-4'}`}
            variant="primary"
            size="lg"
            type="submit"
          >
            {signIn ? 'Sign in' : 'Submit'}
          </Button>
          {notFilled && <p className={styles.error}>You must fill out all forms</p>}
          {!signInValid && <p className={styles.error}>Incorrect email or password</p>}
          {!emailValid && <p className={styles.error}>Email already associated with an account</p>}
          {!emailFormValid && <p className={styles.error}>Invalid email</p>}
        </Form>
      </Col>
    </Container>
  );
};


export default Access;
