import {useEffect} from 'react';
import { BsSun } from 'react-icons/bs';

// Components
import TextButtonImageSection from '../components/TextButtonImageSection';
import HouseImageCardSection from '../components/HousePage/HouseImageCardSection';
import SummerHousingDropdown from '../components/HousePage/SummerHousingDropdown';

// Images
import bostonSkylineAerialColor_jpeg from '../assets/HousePage/screenshot-11-1600x1002.jpeg';
import rooftopNightBostonSkyline_jpg from '../assets/HousePage/IMG_1336.jpg';
import grainFilmHouseAcrossStreet_jpg from '../assets/HousePage/000099900020.jpg';

const HousePage = () => {
    useEffect(() => {
        document.title = 'House'
     }, []);

    return (
        <div>
            <TextButtonImageSection config={{
                imgSrc: grainFilmHouseAcrossStreet_jpg,
                minHeight: '60vh',
                imgConfig: {tintColor: '#353631', tintOpacity: 0.3, attachment: 'fixed', positionY: '130%'},
                headerText: '400 MEMORIAL DR',
                buttonText: 'SUMMER HOUSING',
                buttonLink: '/house#summer',
                buttonIcon: <BsSun/>
            }}/>
            <HouseImageCardSection/>
            <SummerHousingDropdown/>
        </div>
    )
}

export default HousePage;