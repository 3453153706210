import { useEffect, useState } from 'react';
import { BsArrowDownCircle } from 'react-icons/bs';
import { VscHome } from 'react-icons/vsc';
import { GiThreeFriends, GiPartyPopper, GiBookshelf } from 'react-icons/gi';


// Components
import TextButtonImageSection from '../components/TextButtonImageSection';
import TextInfoSection from '../components/TextInfoSection';
import PreloadBrothersPage from './BrothersPage';

// Images
import phiBetaEpsilonHouseColorAmherst_jpeg from '../assets/HomePage/phi-beta-epsilon-mit-epstein-joslin-2-1800x1200.jpeg';
import brotherhoodTriangles_jpg from '../assets/HomePage/brothers-2-2000x580.jpg';
import billardsTableColor_jpg from '../assets/HomePage/house-1-2000x580.jpg';
import rushPartyCrowdDiveBlackWhite_jpg from '../assets/HomePage/rush-2000x580.jpg';
import phiBetaEpsilonHouseBlackWhiteMemorialBlurry_jpg from '../assets/HomePage/history-2000x580.jpg';



const HomePage = () => {

    const [startPreload, setPreload] = useState(false);

    useEffect(() => {
        document.title = 'Home'
        // Preload after the home page renders
        setPreload(true)
     }, []);

    return (
        <div>
            <TextButtonImageSection config={{
                imgSrc: phiBetaEpsilonHouseColorAmherst_jpeg,
                minHeight: '100vh',
                imgConfig: {tintOpacity: 0.4, attachment: 'fixed'},
                headerText: 'Welcome to PBE',
                descriptionText: 'Brotherhood since 1890',
                buttonText: 'EXPLORE',
                buttonLink: '#explore',
                buttonIcon: <BsArrowDownCircle/>
            }}/>
            <TextInfoSection sectionText='For over 120 years, the Phi Beta Epsilon fraternity has shaped the lives of Massachusetts Institute of Technology men. Bringing together individuals of all backgrounds, PBE fosters deep, lifelong friendships. With brothers involved in a diverse range of majors, sports, musical and cultural clubs, PBE holds a large campus presence and leadership.' />
            <TextButtonImageSection config={{
                sectionTag: 'explore',
                minHeight: '80vh',
                imgSrc: brotherhoodTriangles_jpg,
                headerText: 'BROTHERHOOD',
                descriptionText: 'Learn more about the brothers of PBE and our activities around campus',
                buttonText: 'MEET THE BROTHERS',
                buttonLink: '/brothers',
                buttonIcon: <GiThreeFriends/>,
            }}/>
            <TextButtonImageSection config={{
                imgSrc: billardsTableColor_jpg,
                minHeight: '80vh',
                headerText: 'OUR HOME',
                descriptionText: 'Check out our newly renovated house with a roofdeck view of Boston',
                buttonText: 'TOUR THE HOUSE',
                buttonLink: '/house',
                buttonIcon: <VscHome/>
            }}/>
            <TextButtonImageSection config={{
                imgSrc: rushPartyCrowdDiveBlackWhite_jpg,
                minHeight: '80vh',
                headerText: 'JOIN US',
                descriptionText: 'Interested in PBE? Click here to find out more about our rush process ',
                buttonText: 'RUSH SCHEDULE',
                buttonLink: '/rush',
                buttonIcon: <GiPartyPopper/>
            }}/>
            <TextButtonImageSection config={{
                imgSrc: phiBetaEpsilonHouseBlackWhiteMemorialBlurry_jpg,
                minHeight: '80vh',
                headerText: 'EST. 1890',
                descriptionText: 'Connect with our alumni and learn more about the history of Phi Beta Epsilon',
                buttonText: 'LEARN MORE',
                buttonLink: '/history',
                buttonIcon: <GiBookshelf/>
            }}/>
            {/* Usually go to brothers page right after, so worth preloading, but wait till home
            page finishes rendering */}
            {startPreload && 
                <div style={{ display: "none" }}>
                    <PreloadBrothersPage />
                </div>
            }
        </div>
    )
}

export default HomePage;
